import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"
import { colors } from "theme"
import { TransitionGroup, CSSTransition } from "react-transition-group"

// import app components
import { Edges, BackgroundImage, Spacer, Button } from "components"
// import CircleButton from "../circle button"

const ImageCarousel = (props) => {
  const {
    featureContent,
    featureHeadline,
    featureYoutubeUrl,
    featureImages,
    boatLink
  } = props

  const [activeImage, setActiveImage] = useState(0)
  const [nextImage, setNextImage] = useState(1)
  const [resetInterval, setResetInterval] = useState(false)

  // useEffect to control the carousel
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage(
        (prevActiveImage) => (prevActiveImage + 1) % featureImages.length
      )
      setNextImage(
        (prevNextImage) => (prevNextImage + 1) % featureImages.length
      )
    }, 7000)

    // Clear interval on component unmount or on reset
    return () => {
      clearInterval(interval)
    }
  }, [featureImages, resetInterval])

  // Update nextImage when activeImage changes
  useEffect(() => {
    setNextImage((activeImage + 1) % featureImages.length)
  }, [activeImage, featureImages])

  const handlePrevClick = () => {
    setActiveImage(
      activeImage === 0 ? featureImages.length - 1 : activeImage - 1
    )
    setResetInterval(!resetInterval)
  }

  const handleNextClick = () => {
    setActiveImage((activeImage + 1) % featureImages.length)
    setResetInterval(!resetInterval)
  }

  return (
    <Container>
      <div>
        <Images>
          {/* <CircleButtonBox>
            <CircleButton url={featureYoutubeUrl} />
          </CircleButtonBox> */}
          <ImageTopContainer>
            <TransitionGroup>
              <CSSTransition key={nextImage} timeout={1500} classNames="fade">
                {featureImages[activeImage].image && (
                  <BackgroundImage image={featureImages[activeImage].image} />
                )}
              </CSSTransition>
            </TransitionGroup>
          </ImageTopContainer>

          <ImageRightContainer>
            <TransitionGroup>
              <CSSTransition key={activeImage} timeout={500} classNames="fade">
                {featureImages[nextImage].image && (
                  <BackgroundImage image={featureImages[nextImage].image} />
                )}
              </CSSTransition>
            </TransitionGroup>
          </ImageRightContainer>
          <CarouselControl>
            <span>
              {(activeImage + 1).toString().padStart(2, "0")}
              <span className="slash">/</span>
              {featureImages.length.toString().padStart(2, "0")}
            </span>
            <PrevNextButtons>
              <button onClick={handlePrevClick}>PREV</button>
              <Lines>
                {featureImages.length > 0 &&
                  featureImages.map((item, index) => (
                    <span
                      className={
                        index === activeImage ? "activeLine" : "normalLine"
                      }
                      key={index}
                    />
                  ))}
              </Lines>
              <button onClick={handleNextClick}>NEXT</button>
            </PrevNextButtons>
          </CarouselControl>
        </Images>

        <Edges size="cont">
          <Content>
            {featureHeadline && (
              <Typography
                variant="h2"
                children={Parser(featureHeadline)}
                color="inherit"
              />
            )}
            <TextBox>
              {featureContent && (
                <Spacer mt={{ xs: 10 }}>
                  <Typography
                    variant="body1"
                    children={Parser(featureContent)}
                  />
                </Spacer>
              )}
              {boatLink && (
                <ButtonBox>
                  {/* <Spacer mt={{ xs: 30 }}> */}
                  <Button
                    to={boatLink.url}
                    children={boatLink.title}
                    variant="outlined"
                    color="secondary"
                  />
                  {/* </Spacer> */}
                </ButtonBox>
              )}
            </TextBox>
          </Content>
        </Edges>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  padding-top: 60px;
  overflow: hidden;
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: none;
    @media (min-width: 768px) {
      opacity: 1;
      transition: opacity 1500ms ease-in-out;
    }
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: none;
    @media (min-width: 768px) {
      opacity: 0;
      transition: opacity 1500ms ease-in-out;
    }
  }
`

const Images = styled.div`
  margin-bottom: 40px;
  position: relative;
  height: 350px !important;

  @media (min-width: 768px) {
    height: 520px !important;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    height: 400px;
  }
`

const Lines = styled.div`
  display: flex;
  span {
    width: 24px;
    height: 2px;
  }
  .normalLine {
    border-bottom: 1px solid white;
  }
  .activeLine {
    border-bottom: 2px solid white;
    margin-bottom: -2px;
  }
`
const PrevNextButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: flex-end;
  margin-top: 15px;
  button {
    width: fit-content;
    color: white;
    background: none;
    border: none;
    padding: 0 !important;
    cursor: pointer;
  }
`

const ImageTopContainer = styled.div`
  position: relative;
  width: 100%;
  height: 350px;

  @media (min-width: 768px) {
    width: 80%;
    height: 520px;
  }
`

const ImageRightContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    position: absolute;
    display: block;
    top: 50%;
    bottom: 50%;
    right: 0;
    z-index: 10;
    width: 550px;
    height: 320px;
    transform: translate(30%, -50%);
  }
`
const CarouselControl = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 20%;
  height: auto;
  padding: 20px;
  margin-top: 20px;
  min-width: 220px;
  margin-right: 20px;
  background: rgba(27, 27, 27, 0.5);

  @media (min-width: 768px) {
    margin-right: 0px;
    background: none;
  }
  .slash {
    padding-left: 5px;
    padding-right: 5px;
  }
  span {
    color: white;
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
`

const ButtonBox = styled.div`
  width: max-content;
  button {
    white-space: normal;
    font-size: 25px;
    height: 55px;
    @media (min-width: 768px) {
      white-space: nowrap;
    }
  }
`
const TextBox = styled.div`
  display: flex;
  height: auto;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`
const Content = styled.div`
  padding-bottom: 70px;
  color: ${colors.text.light};
`
// const CircleButtonBox = styled.div`
//   position: absolute;
//   top: 55px;
//   left: 70px;
//   z-index: 10;
//   width: auto;
//   height: auto;
// `

export default ImageCarousel

import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import VideoButtonSVG from "../../icons/videoButton.svg"
import { useStore } from "store"

// import app components
import { Edges, Spacer, BackgroundVideo, Button } from "components"
import * as theme from "theme"

const HeroSlider2 = (props) => {
  const { item } = props

  const [, dispatch] = useStore()

  const handleOpenLightbox = (url) => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        slide: 0,
        open: true,
        slides: [{ link: { url } }]
      }
    })
  }
  // const videoUrl = video ? video.localFile.publicURL : null
  if (!item) {
    return false
  }
  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container"
      dotListClass="heroCarouselDots"
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 1
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 1
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 1
        }
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {item &&
        item?.length > 0 &&
        item?.map((slider, index) => {
          const videoUrl = slider?.video
            ? slider?.video.localFile.publicURL
            : null

          return (
            <Container key={index}>
              {slider?.headline && (
                <ContentContainer>
                  <ContentEdge size="md">
                    <Typography variant="h1" children={slider?.headline} />
                    <Typography color="white" children={slider?.text} />
                    {slider?.button?.url && (
                      <Spacer mt={{ xs: 30 }}>
                        <ButtonContainer
                          onClick={() =>
                            !!slider?.button?.url &&
                            handleOpenLightbox(slider?.button?.url)
                          }
                        >
                          <span>{slider?.button?.title}</span>
                          <VideoButtonSVG />
                        </ButtonContainer>
                      </Spacer>
                    )}
                  </ContentEdge>
                </ContentContainer>
              )}

              {videoUrl && (
                <VideoContainer>
                  <BackgroundVideo
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    // placeholder={slider?.image}
                    controls={false}
                    src={videoUrl}
                  />
                </VideoContainer>
              )}
            </Container>
          )
        })}
    </Carousel>
  )
}

const Container = styled.div`
  position: relative;
  height: 500px;
  padding: 0;
  .react-multi-carousel-track .react-multi-carousel-dot-list {
    justify-content: flex-end I !important;
    z-index: 10 I !important;
  }
  .heroCarouselDots {
    justify-content: flex-end I !important;
    z-index: 10 I !important;
  }

  height: 100vh;

  /* &:before {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      52.84% 77.72% at 77.5% 120.75%,
      #000 0%,
      rgba(0, 0, 0, 0) 100%
    );
  } */

  /* &:after {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 300px;
    background: radial-gradient(
      39.7% 55.78% at 31.95% 112.59%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%,
      rgba(255, 255, 255, 0) 100%
    );
  } */
`

const ContentContainer = styled.div`
  height: 100%;
  position: absolute;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: fit-content;
  max-width: 100%;
  top: 0;
  bottom: 0;
  z-index: 20;
  @media (min-width: 768px) {
    max-width: 50%;
    /* padding: 40px; */
  }
  /* padding: 0px; */
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* backdrop-filter: blur(12px); */
  h1 {
    color: white;
    font-size: 36px;
    font-style: normal;
    font-weight: 100 !important;
    line-height: 40px;
    letter-spacing: -0.75px;
    @media (min-width: 768px) {
      font-size: 50px;
      line-height: 50px;
    }
  }
  p {
    color: white;
    padding-top: 20px;
  }
  button {
    background: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
`

const ContentEdge = styled(Edges)`
  display: relative;
  margin-top: auto;
  margin-bottom: auto;
  background: rgba(27, 27, 27, 0.5);
  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 70px;
    padding-right: 70px;
  }
  h1 {
    max-width: 600px;
    @media (min-width: 768px) {
      line-height: 60px;
    }
    margin-bottom: 30px;
  }
  h2 {
    color: white;
    max-width: 500px;
  }
  p {
    padding-top: 5px;
    max-width: 600px;
  }
  padding: 10px;
`

const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 5px 40px;
  width: fit-content;
  border-radius: 25px;
  cursor: pointer;
  border: none;
  span {
    font-family: Roboto Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    padding-right: 11px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
`

const VideoContainer = styled.div`
  position: relative;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      52.84% 77.72% at 77.5% 120.75%,
      #000 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`

export default HeroSlider2

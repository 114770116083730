import React from "react"
import { graphql } from "gatsby"
import Parser from "html-react-parser"
import styled from "styled-components"

// import app components
import {
  Button,
  Edges,
  Featured,
  HomeTextImage2,
  NewsTeaser,
  Spacer,
  HomeTextImage,
  UpcomingEvents
} from "components"

import WaveIcon from "icons/large-wave.svg"

import { colors } from "theme"
import HeroSlider from "../../components/heroSlider"
import HeroSlider2 from "../../components/heroSlider2"
import HeroSlider3 from "../../components/heroSlider3"
import ImageCarousel from "../../components/imageCarousel"
import OurBoats from "../../components/ourBoats"

export default function HomeTemplate(props) {
  const {
    data: {
      page: {
        templateHome: {
          heroSlider,
          heroSlider2,
          heroSlider3,
          textImage,
          textImage2,
          boatFeatureImageCarousel: {
            featureContent,
            featureHeadline,
            featureYoutubeUrl,
            featureImages,
            boatLink
          },
          ourBoats,
          homeNewsModule: newsPosts,
          homeEvents
        }
      }
    }
  } = props

  return (
    <>
      <HeroSlider item={heroSlider?.item} />
      <HomeTextImage {...textImage} />
      <OurBoats {...ourBoats} />

      <HeroSlider2 item={heroSlider2?.item} />
      {featureContent && (
        <FeaturedContainer>
          <ImageCarousel
            featureContent={featureContent}
            featureHeadline={featureHeadline}
            featureYoutubeUrl={featureYoutubeUrl}
            featureImages={featureImages}
            boatLink={boatLink}
          />
          <WaveContainer>
            <WaveIcon />
          </WaveContainer>
        </FeaturedContainer>
      )}
      <HomeTextImage2 {...textImage2} />
      <UpcomingEvents homeEvents={homeEvents} />
      <NewsTeaser newsPosts={newsPosts} />
      <HeroSlider3 item={heroSlider3?.item} />
    </>
  )
}

const FeaturedContainer = styled.div`
  position: relative;
  background: radial-gradient(
    96.63% 96.63% at 25.78% -8.85%,
    #252525 0%,
    #000000 100%
  );
  color: ${colors.text.light};
`

const FeatureIntroText = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`

const WaveContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: -68px;
  right: 0;
  width: calc(100% - 60px);

  svg {
    max-width: 100%;
    width: 100%;
  }
`

export const CollectionQuery = graphql`
  query HomeTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      templateHome {
        boatFeatureImageCarousel {
          featureContent
          featureHeadline
          featureYoutubeUrl
          fieldGroupName
          featureImages {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
          boatLink {
            target
            title
            url
          }
        }
        textImage {
          images {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
          content
        }
        textImage2 {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          section {
            button {
              target
              title
              url
            }
            title
            description
          }
          reviews {
            id
            termTaxonomyId
            contentNodes {
              nodes {
                id
                uri
              }
            }
          }
        }
        ourBoats {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          title
          link {
            title
            url
          }
        }
        heroSlider {
          fieldGroupName
          item {
            fieldGroupName
            headline
            subHeadline
            text
            video {
              localFile {
                publicURL
              }
            }
            button {
              target
              title
              url
            }
          }
        }
        heroSlider2 {
          fieldGroupName
          item {
            fieldGroupName
            headline

            text
            video {
              localFile {
                publicURL
              }
            }
            button {
              target
              title
              url
            }
          }
        }
        heroSlider3 {
          fieldGroupName
          item {
            fieldGroupName
            headline
            text
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            video {
              localFile {
                publicURL
              }
            }
          }
        }

        homeNewsModule {
          ... on WpPost {
            id
            title
            uri
            date
            acf {
              text
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 600
                      quality: 100
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
        homeEvents {
          eventsHeadline
          fieldGroupName
          eventsLink {
            target
            title
            url
          }
        }
      }
    }
  }
`

import React from "react"
import { Typography } from "@material-ui/core"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const OurBoats = (props) => {
  const boats = Object.values(props) // Convert the object to an array

  return (
    <Container>
      <BoatsId id="our-boats" />
      <Typography variant="h1" children={"Discover Our Boats."} />
      <BoatsList>
        {boats.map((boat, index) => (
          <BoatItem key={index} to={boat?.link?.url}>
            <ImageContainer>
              <GatsbyImageStyled
                image={boat?.image?.localFile?.childImageSharp.gatsbyImageData}
                alt={boat?.image?.altText || "Boat Image"}
              />
            </ImageContainer>
            <Typography variant="h2" children={boat?.title} />
            <Typography variant="body1" children={boat?.link?.title} />
          </BoatItem>
        ))}
      </BoatsList>
    </Container>
  )
}

export default OurBoats

const BoatsId = styled.div`
  position: relative;
  top: -50px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 70px;
  h1 {
    margin-top: 35px;
    margin-bottom: 30px;
  }
  p {
    font-weight: 300;
    text-decoration: none;
    color: #252525;
    max-width: 230px;
    font-size: 20px;
  }
`

const BoatsList = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`

const BoatItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 20%; /* Adjust as needed to fit your layout */
  text-align: center;
  padding: 0 2px; /* Add padding for spacing between items */

  @media (max-width: 1200px) {
    margin-bottom: 25px;
    width: 50%; /* Two items per row on small screens */
  }

  @media (max-width: 600px) {
    width: 100%; /* One item per row on extra small screens */
  }
  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    color: black;
    text-decoration: none !important;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 400px; /* Adjust height for vertical length */
  overflow: hidden;
  margin-bottom: 15px;
`

const GatsbyImageStyled = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1); /* Zoom effect on hover */
  }
`
